import { useEffect, useState } from "react";
import TagInputsCheck from "./TagInputCheck";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { post, put } from "../../utility/fetch";
import notification from "../../utility/notification";
import Notify from "../../utility/notify";

const VendorsModal = ({ closeModal, getVendor, data }) => {
    const [payload, setPayload] = useState({
        category: "",
        name: "",
        address: "",
        specialty: "",
        userId: 0
    });
    const handleChange = (event) => {
        setPayload({ ...payload, [event.target.name]: event.target.value });
    }

    const categoryOptions = [
        {
            value: "",
            name: "Select Category"
        },
        {
            value: "specialist",
            name: "Specialist"
        },
        {
            value: "clinic",
            name: "Clinic"
        },
        {
            value: "lab",
            name: "Lab"
        }

    ]

    const createVendor = async () => {
        const { category,
            name,
            address,
            specialty } = payload;


        if (!category || !name || !address || !specialty || category === "" || address === "" || name === "" || specialty === "") {
            Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
            return;
        }


        if (data) {
            let res = await put(`/vendor/${data?.id}`, { ...payload, userId: 12 });
            if (res.statusCode === 400) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 409) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 401) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res) {
                getVendor();
                notification({ message: "Vendor Updated Successfully", type: "success" });
            }
            return;
        }
        else {

            let res = await post("/vendor", { ...payload, userId: 12 })
            if (res.statusCode === 400) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 409) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 401) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res) {
                setPayload({
                    category: "",
                    name: "",
                    address: "",
                    specialty: "",
                    userId: 0
                })
                getVendor();
                notification({ message: "Vendor Created Successfully", type: "success" });
            }

        }


    }



    useEffect(() => {
        if (data) {
            setPayload({
                category: data.category,
                name: data.name,
                address: data.address,
                specialty: data.specialty,
                userId: data.userId,
            })
        }
    }, [])






    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '60%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-80">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Add a vendor</h3>
                        <TagInputs value={payload?.category} type="select" name="category" options={categoryOptions} onChange={handleChange} label="Select Category" />
                        <TagInputs value={payload?.name} onChange={handleChange} name="name" label="Hospital Name" />
                        <TagInputs value={payload?.address} onChange={handleChange} name="address" label="Address" />
                        <TagInputs value={payload?.specialty} onChange={handleChange} name="specialty" label="Specialty" />

                        <button onClick={createVendor} className="btn w-100 m-t-10">Submit</button>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default VendorsModal;