import React from "react";
import { useNavigate } from "react-router-dom";


function FacilityTable({ data }) {

  let navigate = useNavigate()

  const continueUpdate = (id) => {
    sessionStorage?.setItem("patientId", id);
    sessionStorage?.setItem("status", "edit");
    // navigate("/patient-details")
  }


  return (
    <div className="w-100 ">
      <div className="w-100 none-flex-item m-t-40">
      <h3 className="m-b-10">Assigned Beds</h3>

        <table className="bordered-table">
          <thead className="border-top-none">
            <tr className="border-top-none">
              {/* <th>Patient ID</th> */}
              <th>First Name</th>
              <th>Last Name</th>
              <th>Bed Name</th>
              <th>Assigned by</th>
              <th>Assigned Date</th>
            </tr>
          </thead>

          <tbody className="white-bg view-det-pane ">
            {data?.map((row) => (
              <tr className="hovers pointer" onClick={() => continueUpdate(row?.patientId)} key={row.id}>
                {/* <td className="flex flex-v-center">
                  <div className="flex-30">
                    {row?.patient?.id}
                  </div>
                  
                </td> */}
                <td className="flex flex-v-center">
                  <div className="flex-30">
                    {row?.patient?.firstName}
                  </div>
                  {row?.patient?.isReferred && <div className="flex-30 ">
                    <span className="rounded-but">Referred</span>
                  </div>}
                </td>
                <td>{row?.patient?.lastName}</td>
                <td>{row?.bed?.name}</td>
                <td>{`${row?.assignedBy?.firstName} ${row?.assignedBy?.lastName}`}</td>
                <td>{new Date(row?.bedAssignDate)?.toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  );
}

export default FacilityTable;
