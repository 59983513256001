import React from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Swal from "sweetalert2";

export function alertMessage(message, width, height) {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <h3 style={{ color: "white" }}>{message}</h3>
          <button onClick={onClose}>OK</button>
        </div>
      );
    },
  });

  return alert;
}

export const deleteRecord = (callbackfunction, record) => {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <h1 style={{ color: "white" }}>Are you sure</h1>
          <h3 style={{ color: "white" }}>You want to delete this Record?</h3>
          <button  onClick={onClose}>No</button>
          <button  onClick={() => callbackfunction(record)}>Yes!</button>
        </div>
      );
    },
  });

  return alert;
};

export const onYesNoAlert = (callbackfunction, record, message) => {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <h3 style={{ color: "black" }}>{message}</h3>
          <button className="pointer" onClick={onClose}>No</button>
          <button className="pointer" onClick={() => {callbackfunction(record); onClose()}}>Yes!</button>
        </div>
      );
    },
  });

  return alert;
};

export const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger"
  },
  buttonsStyling: false
});

export const deleteAlert = (callback, id, message) => {
  swalWithBootstrapButtons.fire({
      title: message,
      text: "You won't be able to undo this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
  }).then((result) => {
      if (result.isConfirmed) {
          callback(id);
          swalWithBootstrapButtons.fire({
              title: "Deleted!",
              text: "Your record has been deleted.",
              icon: "success"
          });
      } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
      ) {
          // swalWithBootstrapButtons.fire({
          //     title: "Cancelled",
          //     text: "Your imaginary file is safe :)",
          //     icon: "error"
          // });
      }
  });
}


export const alertWarning = (message) => {
  return <div className="alert alert-warning">{message}</div>;
};

export function passwordPop(callbackfunction, getValue) {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="card">
          <div
            className="card-header separator"
            style={{ background: "whitesmoke" }}
          >
            Authenticate Process
          </div>
          <div className="card-body">
            <div className="form-group">
              <label>Enter Password</label>
              <input
                type="password"
                onChange={getValue}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <button className="btn btn-danger" onClick={onClose}>
                Cancel
              </button>
              <button
                className="btn btn-success pull-right"
                onClick={callbackfunction}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      );
    },
  });

  return alert;
}

export default {
  alertMessage,
  passwordPop,
  deleteRecord,
  alertWarning,
  onYesNoAlert,
};
