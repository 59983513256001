import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { PatientData, stats } from "./mockdata/PatientData";
import ReferralTable from "../tables/referralTable";
import { get } from "../../utility/fetch";
import Notify from "../../utility/notify";
import Pagination from "rc-pagination/lib/Pagination";
import HeaderSearch from "../../Input/HeaderSearch";
import HeaderSelect from "../../Input/HeaderSelect";
const filterOptions = [
    {
        name: "Select Filter",
        value: ""
    },
    {
        name: "Active",
        value: "Activate"
    },
    {
        name: "Inactive",
        value: "Idle"
    }
]


function RefferedPatients() {

  const [referredPatients, setAllReferredPatients] = useState([]);
  const [totalPaginationCount, setTotalPaginationCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterOn, setFilterOn] = useState("");
  const [query, setQuery] = useState("")
  const [totalPaginationLength, setTotalPaginationLength] = useState(1);


  const getReferredPatients = async (pageNumber) =>{
    let res = await get(`/referredpatient/list/${pageNumber}/10`)
    if(res.statusCode === 400){
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if(res.statusCode === 404){
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if(res.statusCode === 409){
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if(res.statusCode === 401){
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else{
      setAllReferredPatients(res.resultList);
      setTotalPaginationCount(res.totalPages);
    }
  }

  const handleSearch = async (e) => {
    let query = e.target.value;
    setQuery(e.target.value);
    if (query?.length > 2) {
        let res = await get(`/referredpatient/filter-list/${"ReferredPatientName"}/${query}/${pageNumber}/10`);
        console.log(res);
        if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            console.log(res, res.totalPages);
            setAllReferredPatients(res?.resultList);
            setTotalPaginationLength(res?.totalPages * 10)
        }
    }


}

const handleSelect = async (e) => {
    setFilterOn(e.target.value);
    let res = await get(`/referredpatient/filter-list/AccountStatus/${e.target.value}/${pageNumber}/10`)
    if (res?.statusCode === 404) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 500) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 409) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 401) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else {
        console.log(res, res.totalPages);
        setAllReferredPatients(res?.resultList);
        setTotalPaginationLength(res?.totalPages * 10)
    }

}



  const handlePagination = (pageNumber) => {
    getReferredPatients(pageNumber);
  }

  useEffect(()=>{
    getReferredPatients(1);
  }, [])
 

  return (
    <div className="w-100 m-t-80">
      
      
      <div className="flex w-100 space-between">
      <h3>Referred Patients</h3>
      <div className="flex m-b-10 m-b-10" >
          <div className="m-l-10"><HeaderSearch onChange={handleSearch} /></div>
          <div className="m-l-10"><HeaderSelect options={filterOptions} onChange={handleSelect} /></div> 
          {/* <div className="m-l-10"><button onClick={()=> navigate("/patients/add")} className="btn"><div className="flex flex-v-center"><AiOutlinePlus size={25} color="white"/> <p className="m-l-10">Onboard a Patient</p></div></button></div> */}
          <div>

          </div>
        </div>
        
      </div>

      <div className="">
        <ReferralTable data={referredPatients} />
        <Pagination className="m-t-20" total={totalPaginationCount} onChange={handlePagination} />
      </div>
    </div>
  );
}

export default RefferedPatients;
