import React, { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { del } from "../../utility/fetch";
import notification from "../../utility/notification";
import { onYesNoAlert, swalWithBootstrapButtons, deleteAlert } from "../../utility/alert";
import Pagination from "rc-pagination/lib/Pagination";
import Swal from "sweetalert2";
import ManageEquipmentModal from "../layouts/ManageEquipmentModal";

function ManageEquipmentTable({ data, showModal, vendorData, setVendorData, getEquipment, setShowModal, totalPaginationCount }) {


    const [pageNumber, setPageNumber] = useState(1);




    let navigate = useNavigate()

    const handleEdit = (data) => {
        console.log("edit", data);
        setVendorData(data);
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const handleDelete = async (id) => {
        console.log("delete", id);
        let res = await del(`/equipment/${id}`)
        if (res) {
            getEquipment();
            notification({ message: "Equipment Deleted Successfully", type: "success" });
        }

    }

    const handleChangePagination = (page) => {
        setPageNumber(page);
        getEquipment(page);
    }


    return (
        <div className="w-100 ">
            <div className="w-100 none-flex-item m-t-40">
                <table className="bordered-table">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            <th>S/N</th>
                            <th>Equipment Category</th>
                            <th>Name</th>
                            <th>Details</th>
                            <th>Quantity</th>
                            <th>Date Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody className="white-bg view-det-pane ">
                        {data?.map((row, idx) => (
                            <tr className="hovers" key={row.id}>
                                <td>{(idx + 1) + (10 * (pageNumber - 1))}</td>
                                <td>{row?.equipmentCategory?.name}</td>
                                <td>{row?.name}</td>
                                <td>{row?.details}</td>
                                <td>{row?.quantity}</td>
                                <td>{new Date(row.createdAt)?.toLocaleDateString()}</td>
                                <td>
                                    <div className="flex">
                                        <MdEdit onClick={() => handleEdit(row)} size={30} color="white" className="m-r-10 pointer editButton" />
                                        <MdDelete onClick={() => deleteAlert(handleDelete, row?.id, "Are You Sure You Want To Delete Equipment?")} size={30} color="white" className="deleteButton pointer" />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex flex-h-end m-t-20">
                    <Pagination onChange={handleChangePagination} current={pageNumber} total={totalPaginationCount} />
                </div>
            </div>
            {showModal && <ManageEquipmentModal data={vendorData} getEquipment={getEquipment} closeModal={closeModal} />}
        </div>
    );
}

export default ManageEquipmentTable;
