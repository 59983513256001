import { useEffect, useState } from "react";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { get, post, put } from "../../utility/fetch";
import notification from "../../utility/notification";
import { get as getPatient } from "../../utility/fetchNurse";
import Notify from "../../utility/notify";
import { dateHelp } from "../../utility/general";

const AmbulanceModal = ({ closeModal, setRoomData, getAmbulanceUsageData, data, getRooms }) => {
    const [payload, setPayload] = useState({
        ambulanceId: 0,
        patientId: 0,
        location: "",
        destination: "",
        assignNote: "",
        driverUserId: 0,
        bookedDate: "",
        bookedTime: ""
    });



    const [userNames, setUserNames] = useState([]);
    const [driverNames, setDriverNames] = useState([]);
    const [ambulance, setambulance] = useState([]);


    useEffect(() => {
        getUsers();
        getDrivers();
        getAmbulance();
        if (data) {
            setPayload({
                ambulanceId: data?.ambulanceId,
                patientId: data?.patientId,
                location: data?.location,
                destination: data?.destination,
                assignNote: data?.assignNote,
                driverUserId: data?.driverUserId,
                bookedDate: data?.bookedDate,
                bookedTime: data?.bookedTime
            });
        }
    }, [])




    const getUsers = async () => {
        let res = await get("/patient/list");
        console.log(res);
        let temp = res?.resultList?.map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            value: user.id,
        }))
        temp.unshift({ name: "Select Patient", value: 0 })
        setUserNames(temp);
    }

    const getDrivers = async () => {
        let res = await get("/profile/drivers-list");
        console.log(res);
        let temp = res?.resultList?.map((user) => ({
            name: `${user.firstName || "NIL"} ${user.lastName || "NIL"}`,
            value: user.id,
        }))
        temp.unshift({ name: "Select Driver", value: 0 })
        setDriverNames(temp);
    }

    const getAmbulance = async () => {
        let res = await get(`/equipment/ambulance/list/1/1000`);
        console.log(res)
        let temp = res?.resultList?.map((data) => ({
            name: data.name,
            value: data.id,
        }))
        temp.unshift({ name: "Select Ambulance", value: 0 })
        setambulance(temp);
    }

    const handleChange = (event) => {
        if (event.target.name === "ambulanceId") {
            setPayload({
                ...payload,
                ambulanceId: Number(event.target.value),
            })
        }
        else if (event.target.name === "patientId") {
            setPayload({
                ...payload,
                patientId: Number(event.target.value),
            })
        }
        else if (event.target.name === "driverUserId") {
            console.log(event.target.value)
            setPayload({
                ...payload,
                driverUserId: Number(event.target.value),
            })
        }
        else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
        }
    }

    const assignAmbulance = async () => {

        const {
            ambulanceId,
            patientId,
            location,
            destination,
            assignNote,
            driverUserId,
            bookedDate,
            bookedTime
        } = payload;

        console.log({
            ambulanceId,
            patientId,
            location,
            destination,
            assignNote,
            driverUserId,
            bookedDate,
            bookedTime
        })
        // console.log({
        //     ambulanceName,
        //     ambulanceId,
        //     roomId,
        //     patientAssignedId,
        //     qty,
        //     assignNote,
        //     assignerUserId
        // });

        if (
            !ambulanceId,
            !patientId,
            !location,
            !destination,
            !assignNote,
            !driverUserId,
            !bookedDate,
            !bookedTime) {
            Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
            return;
        }

        // if (data) {
        //     let res = await put(`/assignambulance/${data?.id}`, payload);
        //     if (res) {
        //         Notify({ title: "Success", message: "Room Updated Successfully", type: "success" });
        //         getRooms();
        //         setPayload({
        //             ambulanceName: "",
        //             ambulanceId: 0,
        //             roomId: roomId,
        //             patientAssignedId: 0,
        //             qty: 0,
        //             assignNote: "",
        //             assignerUserId: Number(sessionStorage.getItem("userId"))
        //         });
        //         setRoomData(null);
        //         closeModal();
        //     }
        // }
        // else 

        let res = await post(`/assignambulance`, {...payload, bookedDate: dateHelp(payload?.bookedDate)});
        console.log(res);
        if (res?.statusCode === 409) {
            Notify({ title: "Error", message: "Ambulance Already Assigned", type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res) {
            Notify({ title: "Success", message: "Ambulance Assigned Successfully", type: "success" });
            setPayload({
                ambulanceId: 0,
                patientId: 0,
                location: "",
                destination: "",
                assignNote: "",
                driverUserId: 0,
                bookedDate: "",
                bookedTime: ""
            });
            getAmbulanceUsageData();
            closeModal();

        }
    }

    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-50">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Assign Ambulance to Patient</h3>
                        <TagInputs type="select" value={data && payload?.patientId} options={userNames} name="patientId" onChange={handleChange} label="Select Patient" />
                        <TagInputs type="select" value={data && payload?.ambulanceId} options={ambulance} name="ambulanceId" onChange={handleChange} label="Select Ambulance" />
                        <TagInputs value={data && payload?.location} name="location" onChange={handleChange} label="Location" />
                        <TagInputs value={data && payload?.destination} name="destination" onChange={handleChange} label="Destination" />
                        <TagInputs type= "date" value={data && payload?.bookedDate} name="bookedDate" onChange={handleChange} label="Booked Date" />
                        <TagInputs type="time" value={data && payload?.bookedTime} name="bookedTime" onChange={handleChange} label="Booked Time" />
                        <TagInputs type="select" options={driverNames} value={data && payload?.driverUserId} name="driverUserId" onChange={handleChange} label="Select Driver" />
                        <TagInputs value={data && payload?.assignNote}  type="textArea" onChange={handleChange} name="assignNote" label="Additional notes" />

                        <button onClick={assignAmbulance} className="btn w-100 m-t-10">Submit</button>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default AmbulanceModal;