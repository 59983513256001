import { useEffect, useState } from "react";
import { get, post, put } from "../../../utility/fetch";
import Notify from "../../../utility/notify";
import TagInputs from "../../layouts/TagInputs";
import EquipmentTable from "../../tables/EquipmentTable";
import EquipmentNameTable from "../../tables/EquipmentNameTable";
import EquipmentCategoryTable from "../../tables/EquipmentCategoryTable";

const EquipmentName = () => {

    const [selectedTab, setSelectedTab] = useState("beds");
    const [payload, setPayload] = useState("")
    const [payloadCategory, setPayloadCategory] = useState({
        name: ""
    })
    const [rooms, setRooms] = useState([]);
    const [beds, setBeds] = useState([]);

    const [ambulances, setAmbulances] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [equipmentCategory, setEquipmentCategory] = useState([]);
    const [localTab, setLocalTab] = useState("category");
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [assignmentData, setAssignmentData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(false);
    const [equipmentCategoryPaginationLength, setEquipmentCategoryPaginationLength] = useState(0);
    const [equipmentCategoryPaginationNumber, setEquipmentCategoryPaginationNumber] = useState(1);


    const getBeds = async () => {
        let res = await get("/bed/list/1/100");
        console.log(res);
        if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            setBeds(res?.resultList);
        }
    }

    let handleAssign = (data) => {
        setShowAssignModal(true);
        setAssignmentData(data);
    }


    const clearForm = () => {
        setPayload({
            name: "",
            roomId: "",
            location: "Left",
            note: "",
            userId: Number(sessionStorage.getItem("userId"))
        });
        getRooms();
    }

    const handleRow = (row) => {
        console.log(row)
        setPayloadCategory({ ...payloadCategory, name: row.name });
        setEditId(row?.id);
        setEdit(true);
    }


    const getEquipmentCategory = async (current = 1) => {
        let res = await get(`/equipmentcategory/list/${current}/10`);
        console.log(res);
        if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else{
            setEquipmentCategory(res.resultList);
            setEquipmentCategoryPaginationLength(res?.totalPages * 10)
            setEquipmentCategoryPaginationNumber(current)
        }
    }

    const handleEquipmentCategoryPagination = (current) => {
        getEquipmentCategory(current)
      }



    const getRooms = async () => {
        let res = await get("/room/list/1/100");
        console.log(res);
        if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            let roomList = res?.resultList;
            roomList.unshift({ name: "Select Room", id: "" })
            setRooms(res?.resultList?.map((item) => ({ value: item?.id, name: item.name })));
        }
    }


    const getAmbulance = async () => {
        let res = await get("/assignambulance/list/1/10000")
        if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            console.log(res);
            setAmbulances(res?.resultList);
        }

    }

    // const getEquipment = async () => {
    //     let res = await get("/assignequipment/list/1/10000")
    //     console.log(res);
    //     setEquipment(res?.resultList);
    // }

    const submitCategory = async () => {
        let res;
        const { name } = payloadCategory;
        if (!name) {
            Notify({ title: "Error", message: "Please enter category name", type: "danger" });
            return;
        }

        if (!edit) {
            await post("/equipmentcategory", payloadCategory)
        }
        else {
            await put(`/equipmentcategory/${editId}`, payloadCategory)
        }

        console.log(res);
        if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            if (edit) {
                Notify({ title: "Success", message: "You have successfully updated a Category ", type: "success" });
                setEdit(false);

            }
            else {
                Notify({ title: "Success", message: "You have successfully added a Category ", type: "success" });

            }
            setPayloadCategory({ ...payloadCategory, name: "" });
            getEquipmentCategory();
        }
    }


    useEffect(() => {
        getRooms();
        getBeds();
        getAmbulance();
        // getEquipment();
        getEquipmentCategory()
    }, [])


    const handleChange = (event) => {
        if (event.target.name === "roomId") {
            setPayload({ ...payload, [event.target.name]: Number(event.target.value) })
        }
        else {
            setPayload({ ...payload, [event.target.name]: event.target.value })
        }
    }

    const handleChangeCategory = (event) => {
        setPayloadCategory({ ...payloadCategory, [event.target.name]: event.target.value })
    }



    return (
        <div className=" m-t-20">

            {localTab === "category" && <div className="flex">
                <div className="flex-30 m-r-20 ">
                    <h3 className="m-b-10">Add Equipment Category & Names</h3>
                    {/* <div className="flex "> 
                     
                        <div
                            className={`rounded-button ${localTab === "category" && "bg-green"}`}
                            onClick={() => setLocalTab("category")}
                        >
                            Add Category
                        </div>
                    </div> */}
                    <TagInputs onChange={handleChangeCategory} value={payloadCategory?.name} name="name" label="Category Name" />
                    <button onClick={submitCategory} style={{ height: "40px" }} className="btn w-100">{`${!edit ? "Add" : "Update"} Category Name`}</button>

                </div>
                <div className="flex-60 grid gap-16">
                    <EquipmentCategoryTable equipmentCategoryPaginationNumber={equipmentCategoryPaginationNumber} equipmentCategoryPaginationLength={equipmentCategoryPaginationLength} handleEquipmentCategoryPagination = {handleEquipmentCategoryPagination} handleRow={handleRow} getVendor={getEquipmentCategory} data={equipmentCategory} roomId={payload?.roomId} handleAssign={handleAssign} />
                </div>
            </div>}

        </div>
    );
}

export default EquipmentName;