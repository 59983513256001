import { useEffect, useState } from "react";
import TagInputsCheck from "./TagInputCheck";
import TagInputs from "./TagInputs";
import uploadImage from "../../assets/images/uploadIcon.png"
import { IoMdClose } from 'react-icons/io'
import { get, post, put } from "../../utility/fetch";
import notification from "../../utility/notification";
import Notify from "../../utility/notify";
import axios from "axios";

const ManageEquipmentModal = ({ closeModal, getEquipment, data, ...props }) => {
    const [payload, setPayload] = useState({
        equipmentCategoryId: 0,
        name: "",
        quantity: "",
        image: "",
        details: "",
        isAmbulance: false
    });

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleChange = (event) => {
        if (event.target.name === "equipmentCategoryId" || event.target.name === "quantity") {
            setPayload({ ...payload, [event.target.name]: Number(event.target.value) })
        }
        else if (event.target.name === "isAmbulance") {
            setPayload({ ...payload, [event.target.name]: event.target.checked })
        }
        else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
        }
    }

    const getCategories = async () => {
        let res = await get("/equipmentcategory/list/1/100");
        let temp = res?.resultList?.map((item) => ({ name: item.name, value: item.id }));
        temp.unshift({ name: "Select Category", value: "" });
        setCategoryOptions(temp);
        console.log(res)
    }



    const createVendor = async () => {
        const {
            equipmentCategoryId,
            name,
            quantity,
            image,
            details,
        } = payload;

        console.log(payload)

        if (!equipmentCategoryId || !name || !quantity || !image || !details) {
            Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
            return;
        }


        if (data) {
            let res = await put(`/equipment/${data?.id}`, { ...payload });
            if (res.statusCode === 400) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 409) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 401) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else {
                getEquipment();
                notification({ message: "Equipment Updated Successfully", type: "success" });
            }
            return;
        }
        else {

            let res = await post("/equipment", { ...payload });
            if (res.statusCode === 400) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 409) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 401) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else  {
                setPayload({
                    equipmentCategoryId: 0,
                    name: "",
                    quantity: "",
                    image: "",
                    details: "",
                    isAmbulance: false
                })
                getEquipment();
                notification({ message: "Equipment Created Successfully", type: "success" });
            }
        }
    }


    const FileUpload = async (data, callbackfunction, type) => {
        let fileProgress;
        let Access_token = sessionStorage.getItem('token');
        const response = axios.post(
            `${process.env.REACT_APP_BASE_URL}/services/api/documents/clinic-admin`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${Access_token}`,
                    "content-type": "multipart/form-data",
                    'content-type': undefined,
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress);
                },
            },
        );
        await response
            .then((response) => {
                let result = response.data;
                callbackfunction(result);
                setUploadProgress(0);
                console.log(response);
                setPayload({ ...payload, image: response?.data?.doclink })
            })
            .catch((error) => {
                callbackfunction(error);

            });
    };

    const handleImage = (e, f) => {
        const data = new FormData();

        const supportedTypes = [
            'jpeg',
            'png',
            'gif',
            'pdf',
            "msword",
            "ppt", "pptx", "vnd.ms-excel", "xls", "xlsx",
            'vnd.openxmlformats-officedocument.wordprocessingml.document',
            'doc',
            'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
        const { type, size } = e.target.files[0] ?? '';

        let doc = e.target.files[0];

        const fileType = type.slice(type.indexOf('/') + 1);

        let defObj = {
            docTitle: '',
            docPath: ''
        };

        if (!supportedTypes.includes(fileType)) {
            Notify({
                title: "Upload Error",
                message: "Unsopported file type",
                type: "danger"
            })
        }


        if (supportedTypes.includes(fileType) && size / 1024 < 500000) {
            data.append('files', doc);
            FileUpload(data, (response) => {
                if (response.code === 1) {
                    defObj.docTitle = doc.name;
                    defObj.docPath = response.doclink;
                    props.sendImagg && props.sendImagg(defObj);
                } else if (response.code === 0) {
                    Notify({
                        title: "Upload Error",
                        message: response?.message,
                        type: "danger"
                    })

                } else {
                    Notify({
                        title: "Upload Error",
                        message: response?.message,
                        type: "danger"
                    })

                }
            }, f);
        }
    };

    useEffect(() => {
        if (data) {
            setPayload({
                equipmentCategoryId: data.equipmentCategoryId,
                name: data.name,
                quantity: data.quantity,
                image: data.image,
                details: data.details,
                isAmbulance: data.isAmbulance
            })
        }
        getCategories()
    }, [])



    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '60%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-80">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Add an Equipment</h3>
                        <TagInputs value={payload?.equipmentCategoryId} type="select" name="equipmentCategoryId" options={categoryOptions} onChange={handleChange} label="Select Equipment Category" />
                        <TagInputs value={payload?.name} onChange={handleChange} name="name" label="Equipment Name" />
                        <TagInputs value={payload?.quantity} onChange={handleChange} name="quantity" label="Quantity" />
                        <label className="w-100 m-r-20 ">
                            <p className="m-t-10 m-b-10">Upload Image:</p>
                            <input onChange={(e) => handleImage(e, "image")} type="file" hidden className="w-100" />
                            <img src={payload?.image || uploadImage} className={`picture-box1 ${!payload?.image && "p-10 m-r-20 "} pointer`} />
                        </label>
                        <TagInputs type="textArea" value={payload?.details} onChange={handleChange} name="details" label="Details" />
                        <div className="flex flex-v-center">
                            <input className="checksbox m-r-10" onChange={handleChange} value={payload && payload?.isAmbulance} type="checkbox" name="isAmbulance" /> <p> Equipment is Ambulance</p>
                        </div>

                        <button onClick={createVendor} className="btn w-100 m-t-10">Submit</button>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default ManageEquipmentModal;