import React from "react";
import { RiHotelBedFill } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";

function FacilityCard({ data, handleAssign }) {
  return (
    <div>
      {data?.status === "Vacant" ? (
        <div>
          <div className="card">
            <p className="right-text">{data?.location[0]?.toUpperCase()}</p>
            <RiHotelBedFill size={98} className="text-green" />
            <p className="center-text">{data?.name}</p>
          </div>
          <div className="flex flex-v-center">
            <div className="comment-btn w-80 m-r-10">
              <p className="text-center p-10">{data?.status}</p>
            </div>
            <GrEdit onClick={() => handleAssign(data)} size={30} className="pointer" />
          </div>

        </div>
      ) : (data?.status === "Unavailable") ?
        (
          <div>
            <div className="red-card">
              <p className="right-text">{data?.location[0]?.toUpperCase()}</p>
              <RiHotelBedFill  size={98} className="text-red" />
              <p className="center-text">{data?.name}</p>
            </div>
            <div className="flex flex-v-center">
              <div className="comment-btn-red w-80 m-r-10">
                <p className="text-center p-10 bg-red text-white">{data?.status}</p>
              </div>
              <GrEdit color="red"  onClick={() => handleAssign(data)} size={30} className="pointer" />
            </div>
          </div>
        )
        : (
          <div>
            <div className="card gray-bg">
              <p className="right-text">{data?.location[0]?.toUpperCase()}</p>
              <RiHotelBedFill size={98} className="text-gray" />
              <p className="center-text">{data?.name}</p>
            </div>
            <div className="flex flex-v-center">
              <button className="comment-btn w-80 m-r-10">
                <p className="text-center p-10">{data?.status}</p>
              </button>
              <GrEdit size={30} onClick={() => handleAssign(data)} className="pointer" />
            </div>
          </div>
        )}
    </div>
  );
}

export default FacilityCard;
