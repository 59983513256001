import { useEffect, useState } from "react";
import TagInputsCheck from "./TagInputCheck";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { get, post, put } from "../../utility/fetch";
import notification from "../../utility/notification";
import Notify from "../../utility/notify";

const ManageEquipmentCategoryModal = ({ closeModal, getEquipment, data }) => {
    const [payload, setPayload] = useState({
        equipmentCategoryId: 0,
        name: "",
        quantity: "",
        image: "",
        details: "",
        isAmbulance: false
    });

    const [categoryOptions, setCategoryOptions] = useState([]);

    const handleChange = (event) => {
        if (event.target.name === "equipmentCategoryId" || event.target.name === "quantity") {
            setPayload({ ...payload, [event.target.name]: Number(event.target.value) })
        }
        else if (event.target.name === "isAmbulance") {
            setPayload({...payload, [event.target.name]: event.target.checked })
        }
        else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
        }
    }

    const getCategories = async () => {
        let res = await get("/equipmentcategory/list/1/100");
        setCategoryOptions(res?.resultList?.map((item)=>({name: item.name, value: item.id})))
        console.log(res)
    }



    const createVendor = async () => {
        const {
            equipmentCategoryId,
            name,
            quantity,
            image,
            details,
        } = payload;

        console.log(payload)

        if (!equipmentCategoryId || !name || !quantity || !image || !details) {
            Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
            return;
        }


        if (data) {
            let res = await put(`/equipment/${data?.id}`, { ...payload});
            if (res.statusCode === 400) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 409) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 401) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res) {
                getEquipment();
                notification({ message: "Equipment Updated Successfully", type: "success" });
            }
            return;
        }
        else {

            let res = await post("/equipment", { ...payload});
            if (res.statusCode === 400) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 409) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 401) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res) {
                setPayload({
                    equipmentCategoryId: 0,
                    name: "",
                    quantity: "",
                    image: "",
                    details: "",
                    isAmbulance: false
                })
                getEquipment();
                notification({ message: "Equipment Created Successfully", type: "success" });
            }

        }


    }



    useEffect(() => {
        if (data) {
            setPayload({
                equipmentCategoryId: data.equipmentCategoryId,
                name: data.name,
                quantity: data.quantity,
                image: data.image,
                details: data.details,
                isAmbulance: data.isAmbulance
            })
        }
        getCategories()
    }, [])






    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '60%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-80">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Add an Equipment</h3>
                        <TagInputs value={payload?.equipmentCategoryId} type="select" name="equipmentCategoryId" options={categoryOptions} onChange={handleChange} label="Select Equipment Category" />
                        <TagInputs value={payload?.name} onChange={handleChange} name="name" label="Equipment Name" />
                        <TagInputs value={payload?.quantity} onChange={handleChange} name="quantity" label="Quantity" />
                        <TagInputs value={payload?.image} onChange={handleChange} name="image" label="Upload Image" />
                        <TagInputs type="textArea" value={payload?.details} onChange={handleChange} name="details" label="Details" />
                        <div className="flex flex-v-center">
                            <input className="checksbox m-r-10" onChange={handleChange} value={payload && payload?.isAmbulance} type="checkbox" name="isAmbulance" /> <p> Equipment is Ambulance</p>
                        </div>

                        <button onClick={createVendor} className="btn w-100 m-t-10">Submit</button>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default ManageEquipmentCategoryModal;