import React, { useEffect, useState } from "react";
import InputField from "../../UI/InputField";
import TagInputs from "../../layouts/TagInputs";
import notification from "../../../utility/notification";
import { post } from "../../../utility/fetch";

function ContactDetails({setSelectedTab, data}) {

  const [payload, setPayload] = useState();

  const handleChange = (event) => {
    setPayload({...payload, [event.target.name]: event.target.value })
    console.log(payload)
  }

  const submitPayload = async () => {
    if(sessionStorage.getItem("status") === "edit") {
      setSelectedTab("emergencyContact")

    }
    else{
let res = await post("/patients/updateContact", {...payload, patientId:Number(sessionStorage.getItem("patientId")) })
    if (res){
      notification({message:res?.messages, type: "success"})
      setSelectedTab("emergencyContact")
    }
    }
    
  }

  useEffect(() => {
    data && setPayload(data);
  }, [data])

  return (
    <div>
      <div className="w-50">
        {" "}
        <div className="m-t-40"></div>
        <TagInputs value = {payload && payload?.stateOfResidence} onChange = {handleChange} name ="stateOfResidence"  label="State Of Residence" />
        <TagInputs value = {payload && payload?.lgaResidence} onChange = {handleChange} name ="lgaResidence" label="LGA" />
        <TagInputs value = {payload && payload?.city} onChange = {handleChange} name ="city" label="City" />
        <TagInputs value = {payload && payload?.homeAddress} onChange = {handleChange} name ="homeAddress" label="Home Address" />
        <TagInputs value = {payload && payload?.phone} onChange = {handleChange} name ="phone" label="Phone Number" />
        <TagInputs value = {payload && payload?.email} onChange = {handleChange} name ="email" label="Email Address" />
        <TagInputs value = {payload && payload?.altPhone} onChange = {handleChange} name ="altPhone" label="Alt Phone Number" />

        <button onClick={submitPayload} className="btn  m-t-20 w-100" >Continue</button>

      </div>
    </div>
  );
}

export default ContactDetails;