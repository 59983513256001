import React, { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { del } from "../../utility/fetch";
import notification from "../../utility/notification";
import { onYesNoAlert, swalWithBootstrapButtons, deleteAlert } from "../../utility/alert";
import Pagination from "rc-pagination/lib/Pagination";
import Swal from "sweetalert2";
import ManageEquipmentModal from "../layouts/ManageEquipmentModal";

function ShowEquipmentUsageTable({ data,
    showModal,
    totalEquipmentUsagePaginationLength,
    handleEquipmentUsagePagination,
    vendorData,
    setVendorData,
    getEquipment,
    setShowModal,
    totalPaginationCount
}) {
    const [pageNumber, setPageNumber] = useState(1);

    let navigate = useNavigate()

    const handleEdit = (data) => {
        console.log("edit", data);
        setVendorData(data);
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const handleDelete = async (id) => {
        console.log("delete", id);
        let res = await del(`/equipment/${id}`)
        if (res) {
            getEquipment();
            notification({ message: "Equipment Deleted Successfully", type: "success" });
        }

    }

    const handleChangePagination = (page) => {
        setPageNumber(page);
        getEquipment(page);
    }


    return (
        <div className="w-100 ">
            <div className="w-100 none-flex-item m-t-40">
                <table className="bordered-table">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            {/* <th>Patient ID</th> */}
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Room/Ward</th>
                            <th>Equipment Assigned</th>
                            <th>Assigned By</th>
                            <th>Date Assigned</th>
                        </tr>
                    </thead>

                    <tbody className="white-bg view-det-pane ">
                        {data?.map((row, idx) => (
                            <tr className="hovers" key={row.id}>
                                {/* <td>{(idx + 1) + (10 * (pageNumber - 1))}</td> */}
                                <td>{row?.patient?.firstName}</td>
                                <td>{row?.patient?.lastName}</td>
                                <td>{row?.room?.name}</td>
                                <td>{row?.equipment?.name}</td>
                                <td>{`${row?.assignedBy?.firstName} ${row?.assignedBy?.lastName}`}</td>
                                <td>{new Date(row?.assignDate)?.toLocaleDateString()}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex flex-h-end m-t-20">
                    <Pagination
                        onChange={handleEquipmentUsagePagination}
                        current={pageNumber}
                        total={totalEquipmentUsagePaginationLength}
                    />
                </div>
            </div>
            {showModal && <ManageEquipmentModal data={vendorData} getEquipment={getEquipment} closeModal={closeModal} />}
        </div>
    );
}

export default ShowEquipmentUsageTable;
