import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import { RiDeleteBinFill } from "react-icons/ri";
import RoleAssignmentModal from "../layouts/RoleAssignmentModal";
import {deleteAlert, onYesNoAlert } from "../../utility/alert"
import Pagination from "rc-pagination/lib/Pagination";
import { getRoles } from "@testing-library/react";
import { del, get } from "../../utility/fetch";
import Notify from "../../utility/notify";


const RolesTable = ({ data, total }) => {

    let navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(1);
    const [rolesData, setRolesData] = useState([]);
    const [totalPaginationCount, setTotalPaginationCount] = useState(1);

    const handleChangePagination = (page) => {
       
        getRoles(page);
    }

    useEffect(()=>{
        getRoles();
    }, [])
    
    const getRoles = async (page = 1) => {
        let roles = await get(`/role/list/${page}/10`);
        console.log(roles);
        if (roles) {
            setRolesData(roles?.resultList);
            setTotalPaginationCount(roles?.totalPages);
            setPageNumber(page);
        }
    }

    const deleteRoles = async (id) =>{
        let res = await del(`/role/${id}`)
        if (res) {
          Notify({title:"Success", message: "Role Deleted Successfully", type: "success" });
          getRoles();
        }
      }

    return (
        <div className="w-100 ">
            <div className="w-100 none-flex-item m-t-40">
                <h3 className="m-b-10">Roles Log</h3>

                <table className="bordered-table">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            <th>S/N</th>
                            <th>Class</th>
                            <th>Date Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody className="white-bg view-det-pane ">
                        {rolesData?.map((row, idx) => (
                            <tr className="hovers" key={row.id}>
                                <td>{(idx + 1)+(10*(pageNumber-1)) }</td>
                                <td>{row.name}</td>
                                <td>{new Date(row.createdAt).toLocaleDateString()}</td>
                                <td>
                                    <div className="flex"> 
                                    {/* <MdEdit onClick={()=>handleEdit(row?.id)} size={30} color="white" className="m-r-10 pointer editButton"/> */}
                                    <MdDelete onClick={() => deleteAlert(deleteRoles, row?.id, "Are You Sure You Want To Delete Role?")} size={30} color="white" className="deleteButton pointer"/>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex flex-h-end m-t-20">
                     <Pagination onChange={handleChangePagination} current={pageNumber} total={totalPaginationCount*10}/>
                </div>
            </div>
            {/* <RoleAssignmentModal /> */}
        </div>
    );
}

export default RolesTable;
