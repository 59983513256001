import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { get, put } from "../../utility/fetch"
import notification from "../../utility/notification"
import RoleAssignmentModal from "../layouts/RoleAssignmentModal";
import { deleteAlert, onYesNoAlert } from "../../utility/alert";
import Pagination from "rc-pagination/lib/Pagination";
import Notify from "../../utility/notify";


function PriviledgeTable() {
    const [payload, setPayload] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [roleName, setRoleName] = useState("")
    const [pageNumber, setPageNumber] = useState(1);
    const [rolesData, setRolesData] = useState([]);
    const [totalPaginationCount, setTotalPaginationCount] = useState(1);

    

    let navigate = useNavigate();

    useEffect(()=>{
        getRoles();
    }, [])

    const getRoles = async (page = 1) => {
        let roles = await get(`/role/list/${page}/10`);
        console.log(roles);
        if (roles) {
            setRolesData(roles?.resultList);
            setTotalPaginationCount(roles?.totalPages);
            setPageNumber(page);
        }
    }

    const submit = async (payload, id) => {

        let arr = Object.keys(payload).filter(key => payload[key] === true)
        let data = { rolePrivilegeAccesses: arr.map(key => ({ name: key })) };
        console.log(payload)
        console.log(arr.map(key => ({ name: key })))
        let res = await put(`/role/add-privilege/${id}`, data);

        if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });

        }
        else {
            Notify({ title: "Success", message: "Role Privileges Have been Updated Successfully", type: "success" });
            getRoles();
        }
    }


    const handleChangePagination = (page) => {
        getRoles(page);
    }


    const closeModal = () => {
        setShowModal(false);
    }

    const handleEdit = (role) => {
        console.log("edit", role);
        setRoleName(role);
        setShowModal(true);
    }

    const handleDelete = (id) => {
        console.log("delete", id)

    }


    return (
        <div className="w-100 ">
            <div className="w-100 none-flex-item ">
                <h3 className="m-b-10">Roles Log</h3>

                <table className="bordered-table">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            <th>S/N</th>
                            <th>Class</th>
                            <th>Modules</th>
                            <th>Date Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody className="white-bg view-det-pane ">
                        {rolesData?.map((row, idx) => (
                            <tr className="hovers" key={row.id}>
                                <td>{(idx + 1) + (10 * (pageNumber - 1))}</td>
                                <td>{row.name}</td>
                                <td>{row.rolePrivilegeAccesses?.map((item) => (`${item?.name},`))}</td>
                                <td>{new Date(row.createdAt).toLocaleDateString()}</td>
                                <td>
                                    <div className="flex">
                                        <MdEdit onClick={() => handleEdit(row)} size={30} color="white" className="m-r-10 pointer editButton" />
                                        {/* <MdDelete onClick={() => deleteAlert(handleDelete, row?.id, "Are You Sure You Want To Delete Privilege?")} size={30} color="white" className="deleteButton pointer" /> */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex flex-h-end m-t-20 w-100 ">
                    <Pagination onChange={handleChangePagination} current={pageNumber} total={totalPaginationCount * 10} />
                </div>
                {showModal && <RoleAssignmentModal role={roleName} closeModal={closeModal} payload={payload} setPayload={setPayload} submit={submit} />}
            </div>
        </div>
    );
}

export default PriviledgeTable;
