import { useEffect, useState } from "react";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { post, put } from "../../utility/fetch";
import notification from "../../utility/notification";
import Notify from "../../utility/notify";

const RoomsModal = ({ closeModal, setRoomData, data, getRooms }) => {
    const [payload, setPayload] = useState({
        name: "",
        description: "",
        isWard: false,
        // facilityId:2,
        userId: 0
    })

    console.log(data)


    useEffect(() => {

        if (data) {
            setPayload({
                name: data?.name,
                description: data?.description,
                isWard: data?.roomType === "Ward" ? true : false,
                userId: data?.userId
            })
        }

    }, [])

    const handleChange = (event, isCheck) => {
        console.log(event.target.checked);
        if (isCheck === "check") {
            setPayload({ ...payload, [event.target.name]: event.target.checked });
        }
        else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
        }
    }


    console.log(payload);
    console.log(data);

    const addRooms = async () => {
        console.log(payload);
        const { name,
            description,
            isWard,
        } = payload;

        if (!name || !description || name === "" || description === "") {
            Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
            return;
        }

        if (data) {
            let res = await put(`/room/${data?.id}`, { ...payload, userId: Number(sessionStorage.getItem("userId")) });
            if (res?.statusCode === 409) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 404) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 401) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 500) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }else if (res.statusCode === 400) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else {
                Notify({ title: "Success", message: "Room Updated Successfully", type: "success" });
                getRooms();
                setPayload({
                    name: "",
                    description: "",
                    isWard: false,
                    userId: 0
                });
                setRoomData(null);
                closeModal();
            }
        }
        else {
            let res = await post(`/room`, { ...payload, userId: Number(sessionStorage.getItem("userId")) });
            console.log(res);
            if (res?.statusCode === 409) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 404) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 401) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res.statusCode === 500) {
                Notify({ title: "Error", message: res.errorData[0], type: "danger" });
                return;
            }
            else if (res) {
                Notify({ title: "Success", message: "Room Created Successfully", type: "success" });
                getRooms();
                setPayload({
                    name: "",
                    description: "",
                    isWard: false,
                    userId: 0
                });
                setRoomData(null);
                closeModal();
            }
        }

    }


    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-80">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Create A Room</h3>
                        <TagInputs value={data && payload?.name} name="name" onChange={handleChange} label="Room Name" />

                        <TagInputs value={data && payload?.description} type="textArea" onChange={handleChange} name="description" label="Description" />

                        <div className="flex flex-v-center"><input checked={data && payload?.isWard} type="checkbox" onChange={(e) => handleChange(e, "check")} name="isWard" className="checksbox" /> <p>This Room is a ward</p></div>


                        <button onClick={addRooms} className="btn w-100 m-t-10">Submit</button>


                    </div>
                </div>
            </div>

        </div >
    )
}

export default RoomsModal;